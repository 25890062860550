@import url('https://cdn.jsdelivr.net/gh/jgthms/minireset.css@master/minireset.min.css');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

* {
  font-family: 'Segoe UI', Arial, sans-serif;
}

html,
body,
#root {
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

#ole-toolbar {
  position: absolute;
  right: 20px !important;
  top: 20px !important;
}

.ole-dialog {
  position: absolute;
  right: 20px !important;
  top: 100px !important;
}

button > a {
  text-decoration: none;
  color: unset;
}

.timeline {
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: 'black';
}

fieldset#root {
  width: 100%;
  height: 26em;
  overflow-y: auto;
}

form.rjsf > div > button.btn {
  display: none;
}

fieldset > div.form-group.field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em;
  padding: 1em;
  border: 1px solid black;
  border-radius: 0.5em;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.react-flow__attribution a {
  display: none;
}

.react-flow__minimap {
  display: none;
}

.react-flow__edge-path,
.react-flow__connection-path {
  stroke-width: 2;
}

.react-flow__nodes {
  z-index: -2 !important;
}

.recharts-wrapper svg {
  overflow: visible !important;
}
