.loginContainer {
    width: 80%;
    padding: 3em;
    background: rgb(252 251 251 / 40%);
    border-radius: 16px;
    backdrop-filter: "blur(10px)";
    box-shadow: "-1px 1px 10px 0px #00000040";
}

.inputContainer {
    width: 100%;
    border-radius: 8px !important;
    border: 1px solid #E5E5E5 !important;
}

.signUpContainer {
    width: 80%;
    padding: 3em;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #D1D0F8;
    color: #000000;
    backdrop-filter: blur(10px);
    box-shadow: -1px 1px 10px 0px #00000040
}

.supInput {
    width: 100%;
    border-radius: 8px !important;
}

.privacyLink {
    display: block;
}

.verificationContainer {
    width: 80%;
    padding: 3em;
    background: rgb(252 251 251 / 40%);
    border-radius: 16px;
    backdrop-filter: blur(10px);
    box-shadow: -1px 1px 10px 0px #00000040
}


.input-box {
    width: 20% !important;
    font-size: 2rem !important;
    text-align: center !important;
    border: none !important;
    border-radius: 4px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.tstOtpContainer {
    display: flex;
    justify-content: space-between;
}

.otp-input {
    width: 20% !important;
    background: #E5E5E5 !important;
    margin-bottom: .5em;
    border: none;
    font-size: 1.5rem !important;
    padding: 8px;
    text-align: center;
    border-radius: 4px;
}

.eulaHide {
    display: block;
}


@media screen and (max-width: 768px) {
    .loginContainer {
        width: 93%;
        margin: 2em auto 1em auto;
        box-shadow: none;
        display: flex;
        padding: 1em;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .loginForm {
        width: 100%;
    }

    .inputContainer {
        border: 1px solid #E5E5E5 !important;
        width: 100%;
        border: none;
        border-radius: 8px;
    }

    .signUpContainer {
        width: 93%;
        margin: 2em auto 1em auto;
        backdrop-filter: none;
        background: #FFFFFF;
        padding: 2em 1em;
        box-shadow: none
    }

    .privacyLink {
        display: none;
    }

    .verificationContainer {
        width: 100%;
        padding: 3em;
        background: none;
        backdrop-filter: none;
        box-shadow: none;
    }


    .input-box {
        background: #E8E8E8 !important;
        display: block !important;

    }

    .tstOtpContainer {
        display: flex;
        justify-content: space-between;
    }

    .otp-input {
        width: 20% !important;
        background: #E8E8E8 !important;
        border: none;
        font-size: 1.5rem !important;
        padding: 8px;
        text-align: center;
        border-radius: 4px;
    }

    .eulaHide {
        display: none;
    }


}