.formContainer {
    margin-top: 7em;
    background: white;
    border-radius: 8px;
    flex: 1;
    width: 85%;
    padding: 2em;
}

.footerInfo {
    color: black;
    font-size: .8vw;
}

.hiddenTxt {
    display: inline;
}

@media screen and (max-width: 768px) {
    .formContainer {
        margin-top: 1em;
        background: white;
        border-radius: 8px;
        flex: 1;
        width: 100%;
        padding: 0;
    }

    .footerInfo {
        color: black;
        font-size: .7rem;
    }

    .hiddenTxt {
        display: none;
    }
}