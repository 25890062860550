.form-Container {
    flex-grow: 1;
    margin-top: 1em;
    width: 70%;
}

.input {
    width: 100%;
    border: 1px solid #D1DBE8 !important;
    padding: 12px;
    background-color: #F7FAFC !important;
}

.custome-input {
    font-size: 16px !important;
    padding: 12px 30px !important;
    border-radius: 4px !important;
    width: 100%;
    border: 1px solid #D1DBE8 !important;
    background-color: #F7FAFC !important;
}

.mailText {
    margin-bottom: .5rem;
    font-size: 1rem !important;
    font-weight: 600;
    display: block;
}

.mailTextHide {
    display: block;
}


@media screen and (max-width: 768px) {
    .form-Container {
        margin-top: 1em;
        width: 100%;
    }

    .input {
        width: 100%;
        border: 1px solid #D1DBE8 !important;
        padding: 5px;
        background-color: #F7FAFC !important;
    }

    .custome-input {
        font-size: 16px !important;
        padding: 9px 20px !important;
        border-radius: 4px !important;
        width: 100%;
        border: 1px solid #D1DBE8 !important;
    }

    .mailText {
        display: none;
    }

    .mailTextHide {
        display: none;
    }


}