.heading {
    text-align: center;
    font-size: 2.5rem;
    padding-top: 1em;
    padding-bottom: 1em;
    font-weight: bold;
}

.kfContainer {
    background: #2B2F471A;
    position: relative;
    height: 35vh;
}

.boxContainer {
    width: 100%;
    position: absolute;
    top: 37%
}

.kfAllBox {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
}

.kfBox {
    border: 1px solid #707070;
    box-shadow: rgb(192, 181, 181) 0px 1px 2px 0px, rgb(205, 205, 205) 0px 1px 3px 1px;
    width: 25%;
    height: calc(43vh - 1rem);
    background: white;
    display: flex;
    padding: 2em;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    margin: 1em 0;
}

.kfBox img {
    width: 20%;
}

.lmContainer {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lmBoxContainer {
    height: calc(40vh - 1rem);
    width: 25%
}

.lmRight {
    margin-right: 3em;
}

.lmLeft {
    margin-left: 3em;
}

.imgInner {
    width: 5vw;
    height: 5vw;
    max-width: 100px;
    max-height: 100px;
    background: #2B2F471A;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgInner img {
    width: 60%;
    object-fit: cover;
}

.imgOuter {
    width: 6vw;
    height: 6vw;
    max-width: 100px;
    max-height: 100px;
    border: 2px solid #E3E3E3;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lmLabel {
    font-size: 1.2rem;
    font-weight: 500;
}

.row {
    display: flex;
    justify-content: space-between;
    height: 35vh;
    margin-bottom: 2em;
}

.row>div {
    width: 50%;
}

.row>img {
    width: 50%;
}

.row>div>h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0.5em;
}

.row>div>p {
    width: 90%;
    font-size: 1rem;
    font-weight: 400;
    color: #686876;
    margin-bottom: 0.5em;
}

.row.oddRow {
    flex-direction: row;
}

.row.evenRow {
    flex-direction: row-reverse;
}

.useCaseContainer {
    width: 70%;
    margin: 0 auto;
    padding: 0;
    position: relative;
}

.more {
    margin-left: 1em
}


@media (max-width: 768px) {
    .heading {
        display: none;
    }

    .kfContainer {
        background: none;
        height: auto;
    }

    .kfAllBox {
        width: 100%;
        margin: 0 auto;
        display: block;
    }

    .boxContainer {
        width: 100%;
        position: relative;
        top: 37%
    }

    .kfBox {
        border: 1px solid #707070;
        box-shadow: rgb(192, 181, 181) 0px 1px 2px 0px, rgb(205, 205, 205) 0px 1px 3px 1px;
        width: 100%;
        height: auto;
        background: white;
        display: flex;
        padding: 2em 1em;
        justify-content: start;
        flex-direction: column;
        align-items: center;
        margin: 1em 0;
    }

    .kfBox img {
        width: 20%;
    }

    .lmContainer {
        width: 100%;
        margin: 1em auto;
        display: block;
    }

    .lmBoxContainer {
        height: auto;
        width: 100%
    }

    .lmRight {
        margin-right: 0;
    }

    .lmLeft {
        margin-left: 0;
    }

    .imgInner {
        width: 10vw;
        height: 10vw;
        max-width: 100px;
        max-height: 100px;
        background: #2B2F471A;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .imgInner img {
        width: 100%;
        object-fit: cover;
    }

    .imgOuter {
        width: 10vw;
        height: 10vw;
        max-width: 100px;
        max-height: 100px;
        border: 2px solid #E3E3E3;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lmLabel {
        font-size: 1rem !important;
        font-weight: 500 !important;
        margin-left: 1em !important;
    }

    .row {
        display: flex;
        justify-content: space-between;
        height: auto;
    }

    .row.oddRow {
        flex-direction: column;
    }

    .row.evenRow {
        flex-direction: column;
    }

    .row>div {
        width: 100%;
    }

    .row>img {
        width: 100%;
    }

    .row>div>h2 {
        font-size: 1rem;
        line-height: 1.1rem;
        color: #08201D;
        margin: 0.5em 0;
    }

    .row>div>p {
        width: 90%;
        font-size: .85rem;
        line-height: 1.1rem;
        color: #686876;
        margin-bottom: 0.5em;
        text-align: justify;
    }

    .useCaseContainer {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        position: relative;
    }

    .more {
        margin-left: 0
    }

}