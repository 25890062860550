.wncard {
    width: 700px;
    max-width: 100%;
    height: fit-content;
    border-radius: 20px !important;
    background: white
}

.title {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 600;
    margin-top: .5em
}

.info {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 500;
    margin: 1em 0;
}

.headerContainer {
    text-align: center;
}

.bnHeading {
    font-size: 4rem;
    font-weight: bold;
    line-height: 5rem;
    color: #051F1E;
}

.benifitsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
}

.boxContainer {
    width: 70%;
    display: flex;
    gap: 3em;
    justify-content: space-between;
}

.bnBoxCotainer {
    width: 70%;
    display: flex;
    gap: 3em;
    justify-content: space-between;
}

.box {
    padding: 1em;
    height: 30vh;
}

.boxIcon {
    width: 30%;
    height: 10vh;
}

.boxheading {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #051F1E;
}

.boxInfo {
    font-size: 1rem;
    color: #051F1E;
    line-height: 1.2rem;
    font-weight: 500;
    margin-top: .5em;
}

.wnfooterHeading {
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 3.5rem
}

.wnfooterInfo {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1rem;
}


@media screen and (max-width: 768px) {
    .wncard {
        width: 100%;
        max-width: 100%;
        height: fit-content;
        border-radius: 20px;
        background: white;
        margin: 1em 0;
    }

    .title {
        font-size: 1.3rem;
        line-height: 1.5rem;
        font-weight: 500;
        margin-top: .5em
    }

    .info {
        font-size: .85rem;
        line-height: 1.1rem;
        font-weight: 500;
        margin: 1em 0;
        color: #08201D;
    }

    .bnBoxCotainer {
        width: 100%;
        display: block;
    }

    .bnHeading {
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 600;
        margin-top: .5em;
        text-align: center;
    }

    .box {
        padding: 1em;
        height: 30vh;
        text-align: center;
    }

    .wnfooterHeading {
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 600;
        margin-top: .5em;
        text-align: center;
    }

    .wnfooterInfo {
        font-size: 1rem;
        line-height: 1.2rem;
        text-align: center;
        font-weight: 500;
        margin-top: 1em;
    }
}